import { ref } from 'vue'
import {
  featuredParam,
  apiKeyParam,
  gameIdParam,
} from '@composables/useQueryParams.js'

export const GAME_ID = gameIdParam || 2834
export const PUBLIC_API_KEY = apiKeyParam || '5bf2698252110436284f0e3a0f4d7283'

export const ALLOWED_URLS = globalThis.EMH?.urls || []

export const Style = ref({
  pageBackground: globalThis.EMH?.theme?.page_background,
  buttonRadius: globalThis.EMH?.theme?.button_radius,
  linkColor: globalThis.EMH?.theme?.link_color,
  inputBackgroundColor: globalThis.EMH?.theme?.input_background_color,

  primaryButtonColor: globalThis.EMH?.theme?.primary_button_color,
  primaryButtonColorHover: '#FFFFFF',
  primaryButtonBorderColor: globalThis.EMH?.theme?.primary_button_border_color,
  primaryButtonBorderColorHover:
    globalThis.EMH?.theme?.primary_button_border_hover_color,

  secondaryButtonColor: globalThis.EMH?.theme?.secondary_button_color,
  secondaryButtonColorHover: '#FFFFFF',
  secondaryButtonBorderColor:
    globalThis.EMH?.theme?.secondary_button_border_color,
  secondaryButtonBorderColorHover:
    globalThis.EMH?.theme?.secondary_button_border_hover_color,

  activeButtonColor: globalThis.EMH?.theme?.active_button_color,
  activeButtonColorHover: globalThis.EMH?.theme?.active_button_hover_color,
  activeButtonBorderColor: globalThis.EMH?.theme?.active_button_border_color,
  activeButtonBorderColorHover:
    globalThis.EMH?.theme?.active_button_border_hover_color,

  tileBackgroundColor: globalThis.EMH?.theme?.tile_background_color,
  tileBorderColor: globalThis.EMH?.theme?.tile_border_color,
  tileRadius: globalThis.EMH?.theme?.tile_radius,
})

export const TAG_FILTER = null
export const SHOW_LEGAL = false
export const HOST_URL = '*'
export const FEATURED_TAG = featuredParam
export const FEATURED_LIMIT = 5

export function setStyle(key, value) {
  if (value || value === null) {
    Style.value[key] = value
  }
}
